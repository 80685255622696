<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline"
          >Dashboard</router-link
        >
        <span class="text-gray-400"> / </span>
        <router-link to="/trips" class="text-green hover:underline"
          >Trips</router-link
        >
        <span class="text-gray-400"> / </span>
        <router-link
          v-if="salesCall.trip"
          :to="`/trips/${salesCall.trip.id}`"
          class="text-green hover:underline"
          >Trip {{ parseDate(salesCall.trip.start_date) }}</router-link
        >
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Sales Call {{ salesCall.company_name }}</span>
      </div>
    </div>
  </div>
  <div class="container mx-auto py-5" v-if="!loadingSalesCall">
    <div class="grid grid-cols-3 xl:grid-cols-4">
      <div class="col-span-3 grid grid-cols-3">
        <div class="shadow col-span-1 bg-gray-50 text-center px-4 pt-2 pb-10">
          <span class="block text-gray-400 mt-10">Sales Call</span>
          <h2 class="font-bold text-3xl">{{ salesCall.company_name }}</h2>
          <span class="block mt-10 font-bold">{{ salesCall.title }}</span>
        </div>
        <div class="shadow ml-5 bg-gray-100 col-span-2">
          <div class="bg-gray-50 font-bold px-5 py-2">Attendees</div>
          <div
            class="px-5 py-2"
            :class="{ 'bg-gray-50': key % 2 }"
            v-for="(attendee, key) in salesCall.attendees"
            v-bind:key="attendee.id"
          >
            <div class="flex w-full">
              <span class="mr-5 flex-grow"
                >{{ attendee.name }} ({{ attendee.title }})
                <label
                  class="bg-green-300 text-xs px-1 py-0.5 rounded border"
                  >{{ attendee.type }}</label
                ></span
              >
              <span>{{ attendee.email }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow bg-gray-100 mt-5 col-span-3">
        <div class="bg-gray-50 font-bold px-5 py-2">Action Items</div>
        <div
          class="px-5 py-2"
          :class="{ 'bg-gray-50': key % 2 }"
          v-for="(item, key) in salesCall.action_items"
          v-bind:key="item.id"
        >
          <div class="flex w-full">
            <span class="mr-5 font-bold w-20" v-if="item.completed"
              >Complete <local-date-time :iso-date="item.completed_at"
            /></span>
            <span class="mr-5 font-bold w-20" v-if="!item.completed"
              >To do</span
            >
            <span class="mr-5 flex-grow"
              >{{ item.description }}
              <label v-if="item.responsible"
                >({{ item.responsible.name }})</label
              ></span
            >
            <span class="mr-5 w-60">
              Due: <local-date-time :iso-date="item.due_at" />
            </span>
            <button
              class="text-green text-sm"
              v-if="!item.completed"
              @click.prevent="markComplete(item)"
            >
              Mark Complete
            </button>
            <button
              class="text-red-500 text-sm"
              v-if="item.completed"
              @click.prevent="markIncomplete(item)"
            >
              Mark Incomplete
            </button>
          </div>
        </div>
      </div>
      <div class="col-span-3 border-t border-gray-200 mt-5">
        <div class="shadow mt-5 bg-gray-100">
          <div
            class="px-5 py-2 bg-gray-50 border-b border-gray-200 flex w-full"
          >
            <h2 class="font-bold">Comments</h2>
            <span class="text-sm flex-grow text-right mt-1"
              ><a
                class="text-green hover:underline cursor-pointer"
                @click="showAddCommentModal = true"
                >Add Comment</a
              ></span
            >
          </div>
          <div class="px-2 pb-2" v-if="salesCall.comments.length > 0">
            <div
              class="mt-2 px-3 py-2 bg-gray-50 rounded border border-gray-200"
              v-for="comment in salesCall.comments"
              v-bind:key="comment.id"
            >
              <div class="text-sm flex">
                <span class="inline-block font-bold"
                  >{{ comment.owner.first_name }}
                  {{ comment.owner.last_name }}</span
                >
                <span
                  class="inline-block ml-1 text-gray-400 text-xs flex-grow text-right"
                  >{{ parseDate(comment.created_at) }}</span
                >
              </div>
              <p class="mt-2 text-sm">{{ comment.message }}</p>
            </div>
          </div>
          <div
            class="px-5 py-2 italic text-gray-400 text-sm"
            v-if="salesCall.comments.length == 0"
          >
            No comments yet
          </div>
        </div>
      </div>
    </div>
    <vue-final-modal
      v-model="showAddCommentModal"
      classes="flex justify-center items-center"
      content-class="relative flex flex-col max-h-full w-2/6 mx-4 border dark:border-gray-800 rounded bg-gray-100 dark:bg-gray-900"
    >
      <h3 class="font-bold px-4 py-2 bg-gray-50">Add Comment</h3>
      <div class="px-4 py-2">
        <textarea
          class="mt-2 w-full px-2 py-1 shadow"
          v-model="comment"
          ref="mes"
          rows="4"
        ></textarea>
      </div>
      <div class="px-4 py-2 bg-gray-50 text-right">
        <a
          class="text-sm mx-5 text-green hover:underline cursor-pointer"
          @click="showAddCommentModal = false"
          >Cancel</a
        >
        <button
          class="rounded bg-green text-white px-3 py-1 text-sm font-bold"
          @click="addComment()"
        >
          Add Comment
        </button>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import LocalDateTime from "@/components/common/LocalDateTime";
import axios from "axios";

export default {
  components: { LocalDateTime },
  data: () => {
    return {
      id: null,
      showAddCommentModal: false,
      comment: "",
      loadingSalesCall: true,
    };
  },
  computed: {
    ...mapGetters({
      salesCall: "salesCalls/details",
    }),
  },

  created: function () {
    this.loadSalesCall();
  },

  methods: {
    parseDate(date) {
      let d = new Date(date);
      return d.toLocaleDateString();
    },

    parseTime(date) {
      let d = new Date(date);
      return d.toLocaleTimeString();
    },

    async loadSalesCall() {
      this.loadingSalesCall = true;
      await this.$store.dispatch(
        "salesCalls/loadDetails",
        this.$route.params.id
      );
      document.title =
        "MLE Rep Center | Sales Call " + this.salesCall.company_name;
      this.loadingSalesCall = false;
    },

    async addComment() {
      this.showAddCommentModal = false;
      await this.$store.dispatch("salesCalls/addComment", this.comment);
      this.comment = "";
    },

    async markComplete(actionItem) {
      const tripId = this.salesCall.trip_id;
      const tripSalesCallId = this.salesCall.id;
      const tripSalesCallActionItemId = actionItem.id;
      await axios.post(
        `api/trips/trips/${tripId}/sales-calls/${tripSalesCallId}/action-items/${tripSalesCallActionItemId}/complete`
      );
      window.location.reload();
    },

    async markIncomplete(actionItem) {
      const tripId = this.salesCall.trip_id;
      const tripSalesCallId = this.salesCall.id;
      const tripSalesCallActionItemId = actionItem.id;
      await axios.delete(
        `api/trips/trips/${tripId}/sales-calls/${tripSalesCallId}/action-items/${tripSalesCallActionItemId}/complete`
      );
      window.location.reload();
    },
  },
};
</script>

<template>
  <span>
    <span v-if="localDateTime">
      {{ localDateTime.format(format || defaultFormat) }}
    </span>
    <span v-if="!localDateTime"> N/A </span>
  </span>
</template>

<script>
import moment from "moment";

export default {
  props: ["isoDate", "format"],

  data() {
    return {
      defaultFormat: "MM/DD/YYYY hh:mma",
    };
  },

  computed: {
    localDateTime() {
      console.log(this.isoDate);
      if (!this.isoDate) {
        return null;
      }

      return moment.utc(this.isoDate).local();
    },
  },
};
</script>
